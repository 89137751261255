<template>
  <main class="w-full">
    <div
      v-if="deductions == false"
      class="flex items-center w-full mx-auto border border-outlineGray rounded-xl tracking-wide justify-center gap-x-2 p-2 text-sm font-medium cursor-pointer bg-white"
    >
      <span
        @click="emit('update', true)"
        :class="
          bool
            ? 'bg-darkColor shadow-2xl text-white '
            : 'bg-newGrey md:shadow-none shadow-2xl'
        "
        class="w-full text-xs md:text-sm text-center rounded-lg md:px-5 md:py-2 px-3 py-2"
        >{{ options[0] }}</span
      >
      <span
        @click="emit('update', false)"
        :class="
          !bool
            ? 'bg-darkColor shadow-2xl text-white'
            : 'bg-newGrey md:shadow-none shadow-2xl'
        "
        class="w-full text-xs md:text-sm text-center rounded-lg md:px-5 md:py-2 px-3 py-2"
        >{{ options[1] }}</span
      >
    </div>

    <div v-else class="w-full tracking-wide text-sm font-medium">
      <form
        class="w-full md:gap-x-0 gap-x-2 flex items-center justify-between bg-greyBg px-2 md:px-3 py-3"
        :class="error && error.length ? 'border border-secondary' : undefined"
        style="border-radius: 16px"
      >
        <div class="relative flex items-center gap-2">
          <input
            class="border-2 border-outlineGray"
            name="radio"
            id="radio"
            type="radio"
            :value="bool"
            @click="emit('update', true)"
          />
          <label
            :class="bool ? 'text-secondary' : 'text-dark-800'"
            class="text-xs md:text-sm font-medium"
            for="radio"
            >{{ options[0] }}
            <span
              @click="setHoveredType('auto')"
              @mouseenter="setHoveredType('auto')"
              @mouseleave="clearHoveredType"
              class="hidden inline-block"
              ><img
                class="w-3 ml-1"
                src="@/assets/icons/payroll-info.svg"
                alt=""
            /></span>
          </label>

          <div
            v-if="isHovered('auto')"
            class="absolute w-72 z-50 top-7 mt-2 left-4 bg-white shadow-2xl rounded-xl flex gap-2 py-2 px-3 flex-col mb-2 text-left"
          >
            <p class="font-bold text-xs text-dark-800">
              {{ options[1] }}
            </p>

            <p class="text-xs leading-5">
              This sheet automatically calculates your PAYE, Pension, Net salary
              and all other details of your payroll. You may input only Gross
              salary and allowances on this template sheet.
            </p>
          </div>
        </div>
        <div class="relative flex items-center gap-2">
          <input
            class="border-2 border-outlineGray"
            name="radio"
            id="radio1"
            type="radio"
            :value="!bool"
            @click="emit('update', false)"
          />
          <label
            :class="bool === false ? 'text-secondary' : 'text-dark-800'"
            class="text-xs md:text-sm font-medium"
            for="radio1"
            >Manual input of salary
            <span
              @click="setHoveredType('manual')"
              @mouseenter="setHoveredType('manual')"
              @mouseleave="clearHoveredType"
              class="hidden inline-block"
              ><img
                class="w-3 ml-1"
                src="@/assets/icons/payroll-info.svg"
                alt=""
            /></span>
          </label>

          <div
            v-if="isHovered('manual')"
            class="absolute w-72 z-50 top-7 mt-2 left-4 bg-white shadow-2xl rounded-xl flex gap-2 py-2 px-3 flex-col mb-2 text-left"
          >
            <p class="font-bold text-xs text-dark-800">
              Manual input of Salary
            </p>

            <p class="text-xs leading-5">
              This sheet allows you to input all details of your payroll.
              Eazipay does not automatically calculate any part of your salaries
              here. You may be required to input
              <strong>ALL</strong> part of your salaries including Gross salary,
              Net salary, Allowances, and Statutory Deductions.
            </p>
          </div>
        </div>
      </form>

      <p
        v-show="error && error.length"
        class="mt-1 text-xs text-center text-secondary"
      >
        {{ error }}
      </p>
    </div>
  </main>
</template>

<script setup>
import { ref } from "vue";
const props = defineProps(["bool", "options", "error", "deductions"]);
const emit = defineEmits(["update"]);

const hoveredType = ref("");
const auto = ref(null);
function setHoveredType(type) {
  hoveredType.value = type;
}
function clearHoveredType() {
  hoveredType.value = null;
}
function isHovered(type) {
  return hoveredType.value === type;
}
</script>

<style></style>
